import * as React from "react";
import { graphql } from "gatsby";
import { convert } from "html-to-text";
import { StarIcon } from '@heroicons/react/24/solid'

import Layout from "../components/layout";
import Seo from "../components/seo";
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const ProfessionalsPostTemplate = ({
  data: { site, markdownRemark: professional },
  location,
}) => {
  const siteTitle = site.siteMetadata?.title || `Title`;

  return (
    <Layout location={location} title={siteTitle}>
      <div className="p-5 md:p-10 text-sm bg-gameOnGray mx-auto">
        <div
          className="mb-10 container"
        >
          <div className="max-w-screen-lg mx-auto bg-gameOnLighterGreen p-5 rounded-md">

            <div className="grid grid-cols-1 md:grid-cols-4 md:gap-4">

            <div className="">
              <div className="mb-3">
                <img src={professional.frontmatter.img} alt={`${professional.frontmatter.name} Profile`} className="w-full md:w-44 md:h-auto rounded-md" />
              </div>
              <div className="">
                  <h1 className="text-2xl font-semibold">{professional.frontmatter.name}</h1>
                  <span className="text-gameOnGreen font-medium">{professional.frontmatter.title}</span>
                  <div className="flex items-center xl:col-span-1">
                    <div className="flex items-center my-3">
                      {[0, 1, 2, 3, 4].map((rating) => (
                        <StarIcon
                          key={rating}
                          className={classNames(
                            professional.frontmatter.rating > rating ? 'text-gameOnLightGreenActive' : 'text-gray-200',
                            'h-5 w-5 flex-shrink-0'
                          )}
                          aria-hidden="true"
                        />
                      ))}
                    </div>
                    <p className="ml-3 text-sm text-gray-700">
                      {professional.frontmatter.rating}
                      <span className="sr-only"> out of 5 stars</span>
                    </p>
                  </div>
                  
              </div>
            <a href="https://web.thegameonapp.com/SignupScreen" className="my-5 inline-block w-full mx-auto">
              <button className="px-10 w-full lg:w-auto text-center my-4 lg:my-0 py-5 leading-none text-white font-outfit font-medium text-base rounded-3xl hover:border-transparent bg-gameOnGreen hover:bg-gameOnLightGreenActive lg:mt-0 ">
              Book with Me
              </button>
            </a>
            {professional.frontmatter.promoCode && (
              <p className="">
                Use referral code:<br />
                <span className="text-white inline-block mt-3 font-medium bg-gameOnGreen rounded-lg px-2 py-1">
                  {professional.frontmatter.promoCode}
                </span>
              </p>
            )}
            </div>

            <div className="col-span-3">
              <div className="grid grid-cols-1 md:grid-cols-2  md:gap-4 text-center md:w-96">
                <div className="mb-5 md:my-7 bg-gameOnGray hover:cursor-default rounded-3xl p-5">
                  <h3 className="text-gameOnGreen font-medium text-sm">Specialty</h3>
                  <p className="text-lg text-black">{professional.frontmatter.primarySpecialty}</p>
                </div>
                <div className="mb-5 md:my-7 bg-gameOnGray hover:cursor-default rounded-3xl p-5">
                  <h3 className="text-gameOnGreen font-medium text-sm">Location</h3>
                  <p className="text-lg text-black">{professional.frontmatter.state}</p>
                </div>
              </div>
              <div className="py-5 md:py-0">
                <h4 className="text-gameOnGrey mb-3">About Service</h4>
                <section
                  className="md:pr-10 professional-desc"
                  dangerouslySetInnerHTML={{ __html: professional.html }}
                />
              </div>
            </div>

            </div>

          </div>
        </div>
      </div>
    </Layout>
  );
};

export const Head = ({ data: { markdownRemark: professional } }) => {
  const plainTextDescription = convert(professional.html, {
    wordwrap: 130
  });

  return (
    <Seo
      title={`${professional.frontmatter.name} - ${professional.frontmatter.title}`}
      description={`${professional.frontmatter.name} – ${professional.frontmatter.title}. ${plainTextDescription}`}
    />
  );
};

export default ProfessionalsPostTemplate;

export const query = graphql`
  query ProfessionalPostBySlug(
    $id: String!
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        name
        img
        title
        state
        rating
        promoCode
        primarySpecialty
      }
    }
  }
`;
